import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  private previousUrl: string;
  private currentUrl: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      });
  }

  public getPreviousUrl() {
    return this.previousUrl || document.referrer;
  }

  public getCurrentUrl() {
    return this.currentUrl;
  }

  addQueryParams(link: string): string {
    const existingQueries = link?.indexOf('?')[1];

    this.activatedRoute.queryParams.subscribe((values) => {
      const impersonate = values['impersonate'];
      if (impersonate) {
        const parameter = existingQueries ? '&' : '?';
        link += `${parameter}impersonate=${impersonate}`;
      }
    });
    return link;
  }
}
